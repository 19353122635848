'use strict';

const bnr = document.querySelector('.hero-body'),
    btn = document.querySelector('#btn_hero'),
    imgs = document.querySelectorAll('.hex_M_img img'),
    play = () => {
        bnr.style['animation-play-state'] = 'running';
        btn.setAttribute('title', 'Pause animations');
        btn.setAttribute('aria-label', 'Pause animations');
        btn.setAttribute('class', 'i-pause');
    },
    pause = () => {
        bnr.style['animation-play-state'] = 'paused';
        btn.setAttribute('title', 'Resume animations');
        btn.setAttribute('aria-label', 'Resume animations');
        btn.setAttribute('class', 'i-play');
    },
    toggle = () =>
        bnr.style['animation-play-state'] === 'running' ? pause() : play(),
    fade = () => {
        imgs[currentIndex].classList.remove('active_m_img');
        currentIndex = (currentIndex + 1) % imgs.length;
        imgs[currentIndex].classList.add('active_m_img');
    };
let currentIndex = 0;

if (window.matchMedia('(prefers-reduced-motion: reduce)').matches) toggle();

imgs[currentIndex].classList.add('active_m_img');
btn.addEventListener('click', () => {
    toggle();
});

setInterval(fade, 3000);

